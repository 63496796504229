<template>
  <div class="ss-setting">
    <div class="ss-setting__header">
      <h2 class="header__title">
        {{ $t('schedule.setting.extraConstraintSetting') }}
      </h2>
    </div>
    <div class="ss-setting__body">
      <spinner v-if="isLoading" />
      <template v-else>
        <div
          v-for="file in files"
          :key="file.code"
          class="file"
        >
          <!-- 鈺齊只需要轉換時間 -->
          <single-constraint-file
            v-if="isShowDataFrame(file.code)"
            :file-data="file"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import SingleConstraintFile from './SingleConstraintFile'
import { fetchDataBoundStatus } from '@/schedule/API/Project'
import { DATA_CATEGORY } from '@/schedule/utils/enum'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'ConstraintSetting',
  components: {
    SingleConstraintFile
  },
  data () {
    return {
      isLoading: true,
      files: []
    }
  },
  computed: {
    ...mapState('scheduleSetting', ['scheduleProjectId']),
    ...mapGetters('scheduleSetting', ['isYKSchedule'])
  },
  mounted () {
    this.fetchFiles()
  },
  methods: {
    fetchFiles () {
      this.isLoading = true
      fetchDataBoundStatus(this.scheduleProjectId)
        .then(files => {
          files.forEach(file => {
            if (file.category === DATA_CATEGORY.CONSTRAINT) this.files.push(file)
          })
        })
        .catch(() => {})
        .finally(() => this.isLoading = false)
    },
    isShowDataFrame (code) {
      // 待整併
      if (this.isYKSchedule) {
        return code === 'transfer_time' || code === 'holiday' || code === 'shift_day'
      }
      return code !== 'shift_day'
    }
  }
}
</script>
